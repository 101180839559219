body {
  color:#d9d9d9;
    background: #000;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
 

  @keyframes carImg {
    0%{
      opacity: 0;
      transform:translateX(-10vw);
    }
    100%{
      opacity: 1;
      transform:translateX(0);
    }
  }
/*---------------lib--------------------*/


input:focus,textarea:focus,select:focus{
    outline: none;
  }
  .row{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .column{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
  }
  .fullDev{
    width:100vw;
    height:100vh;
  }
  .midDev{
    width:100vw;
    height:50vh;
  }
  .relative{
    position:relative;
  }
  .absolute{
    position:absolute;
  }
  .b{
    border:1px solid #000;
  }
  .bw{
    border:1px solid #fff;
  }
  
  /*---------------   ---------------*/

 

  /*---------------transitionGroup--------------------*/

  .page-enter {
    opacity: 0;
    transform: translateX(-100vw);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 400ms, transform 600ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: translateX(0);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: translateX(100vw);
    transition: opacity 400ms, transform 600ms;
  }



  .mainCarousel-enter {
    opacity: 0;
    transform: translateX(100vw);
  }
  .mainCarousel-enter-active {
    opacity: 1;
    transition: 700ms ease-in-out;
    transform: translateX(0);
  }
  .mainCarousel-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .mainCarousel-exit-active {
    opacity: 0;
    transition: 700ms ease-in-out;
    transform: translateX(-100vw);
  }




  .sideCarousel-enter {
    opacity: 0;
    transform: translateX(-100vw);
  }
  .sideCarousel-enter-active {
    opacity: 1;
    transition: 700ms ease-in-out;
    transform: translateX(0);
  }
  .sideCarousel-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .sideCarousel-exit-active {
    opacity: 0;
    transition: 700ms ease-in-out;
    transform: translateX(-100vw);
  }

  .mainCarouselMob-enter {
    opacity: 0;
    transform: translateY(-100vw);
  }
  .mainCarouselMob-enter-active {
    opacity: 1;
    transition: 700ms ease-in-out;
    transform: translateY(0);
  }
  .mainCarouselMob-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .mainCarouselMob-exit-active {
    opacity: 0;
    transition: 700ms ease-in-out;
    transform: translateY(-100vw);
  }




  .sideCarouselMob-enter {
    opacity: 0;
    transform: translateY(100vw);
  }
  .sideCarouselMob-enter-active {
    opacity: 1;
    transition: 700ms ease-in-out;
    transform: translateY(0);
  }
  .sideCarouselMob-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .sideCarouselMob-exit-active {
    opacity: 0;
    transition: 700ms ease-in-out;
    transform: translateX(100vw);
  }


  .rentCarousel-enter {
    opacity: 0;
    transform: translateY(-10vw);
  }
  .rentCarousel-enter-active {
    opacity: 1;
    transition: 400ms ease-in-out;
    transform: translateY(0);
  }
  .rentCarousel-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .rentCarousel-exit-active {
    opacity: 0;
    transition: 400ms ease-in-out;
    transform: translateY(10vw);
  }

  .rentCarouselMob-enter {
    opacity: 0;
    transform: translateX(-50vw);
  }
  .rentCarouselMob-enter-active {
    opacity: 1;
    transition: 400ms ease-in-out;
    transform: translateX(0);
  }
  .rentCarouselMob-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .rentCarouselMob-exit-active {
    opacity: 0;
    transition: 400ms ease-in-out;
    transform: translateX(50vw);
  }


  /*---------------router--------------------*/
  
  .active{
    padding:0 0.5vw 0.5vw 0;
    border-bottom:1px solid #d9d9d9;
    border-right:2px solid #d9d9d9;
    color:#ff2c02;
    text-decoration:none;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    transform: skew(-20deg);
  }
  .unactive{
    border-bottom:1px solid transparent;
    padding:1vw;
    color:#d9d9d9;
    text-decoration:none;
    transition: 300ms;
  }
  .mobActive{
    padding:0 2vw 1vw 0;
    border-bottom:1px solid #d9d9d9;
    border-right:2px solid #d9d9d9;
    color:#000;
    text-decoration:none;
    display:flex;
    align-items: flex-end;
    justify-content: center;
    transform: skew(-20deg);
    font-weight: 700;
    text-align: right;
    font-size: 4.5vw;
    width:fit-content;
  }
  .mobUnactive{
    border-bottom:1px solid transparent;
    color:#d9d9d9;
    text-align: right;
    text-decoration:none;
    padding:2vw 1vw;
    z-index: 2;
    font-size: 4.5vw;
    width:fit-content;
  }
  .unactive:hover{
    transform: skew(-20deg);
  }
  /*---------------desktop--------------------background: #ff2c02;*/

  .deskHead{
    margin:0;
    color:#ff2c02;
    font-size:2vw;
    letter-spacing:0.3vw;
    font-weight: 700;
  }
  .mobHead{
    margin:2vw 0 2vw 5vw;
    color:#ff2c02;
    font-size:5.5vw;
    letter-spacing:0.5vw;
    font-weight: 700;
  }
  .deskH1{
    font-family: Libre Baskerville;
    color:#d9d9d9;
    font-weight: 400;
    font-size: 1.9vw;
  }
  .mobH1{
    font-family: Libre Baskerville;
    color:#d9d9d9;
    font-weight: 400;
    font-size: 5vw;
  }

  
  

    /*---------------buttons--------------------*/

    .base-button{
    background:#d9d9d950;
    color:#000;
    }

    .base-button:hover{
      background:#ff2c02;
      color:#000;
      }
    .carouselBtn{
      transition:300ms ease-in-out;
      padding:0.5vw 1vw;
      background:#d9d9d950;
      transform:skew(-20deg);
      cursor:pointer;
      color:#000;
    }
    .carouselBtn:hover{
      background:#ff2c02;
      color:#d9d9d9;
    }
    .carouselPoints{
      cursor:pointer;
      transform:skew(-30deg);
      transition:700ms;
      

    }
    .carouselBtnMob{
      transition:300ms ease-in-out;
      padding:2vw 4vw;
      font-size:5vw;
      background:#d9d9d950;
      transform:skew(-20deg);
      color:#d9d9d9;
    }